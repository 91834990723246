import {
  DeleteOutlined,
  EditOutlined,
  InsertDriveFile,
  Upload,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import skynetLogo from '../../assets/img/ic-skynet-small.png';
import { isAdmin } from '../../helpers/auth';
import { standardToShortDate } from '../../helpers/converters';
import ConfirmationModal from '../ConfirmationModal';
import RequiredDocumentModal from '../RequiredDocumentModal';
// Format like "7/18/2023 10:40pm"
const dateFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

const updateRequiredDocument = (updates, requiredDocumentId, updateType) => {
  return axios.patch(
    `/api/v1/uw/required-documents/${requiredDocumentId}?type=${updateType}`,
    updates,
  );
};

const deleteFile = (fileId) => {
  return axios.patch(`/api/v1/loans/file/${fileId}?type=delete`);
};

const formatDateTime = (date) => {
  return dateFormatter.format(date).split(',').join('');
};

export const RequiredDocument = ({
  document: initialDocumentState,
  onUpdate,
}) => {
  const [documentState, setDocumentState] = useState(initialDocumentState);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState();

  const handleStatusChange = async (e) => {
    const { name, value } = e.target;
    const updates = { [name]: value };
    const updatedDoc = await updateRequiredDocument(
      updates,
      documentState.id,
      'status',
    );
    setDocumentState((prev) => ({
      ...prev,
      ...updatedDoc.data,
    }));
    onUpdate();
  };

  const handleUpload = useCallback(() => {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;

    input.click();
    input.onchange = (_) => {
      const files = input.files ?? [];
      const requests: Promise<any>[] = [];
      for (const file of files) {
        const formData = new FormData();
        formData.append('', file, file.name);
        formData.append('requiredDocumentId', documentState.id);
        formData.append('parentId', documentState.parent_id);

        requests.push(
          axios.post(`/api/v1/rosefield/validate-file`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }),
        );

        requests.push(
          axios.post(`/api/v1/loans/file`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }),
        );
      }

      axios
        .all(requests)
        .then((result) => {
          setDocumentState((prev) => ({
            ...prev,
            files: [...prev.files, ...result.map((r) => r.data)],
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    };
  }, []);

  const handleDelete = async (fileId) => {
    await deleteFile(fileId);
    setDocumentState((prev) => ({
      ...prev,
      files: prev.files.filter((file) => file.id !== fileId),
    }));
    setDeleteModalOpen(false);
  };

  const handleSubmit = async ({ files, ...updates }, id) => {
    const updatedDoc = await updateRequiredDocument(
      updates,
      documentState.id,
      'config',
    );
    setDocumentState((prev) => ({
      ...prev,
      ...updatedDoc.data,
    }));
    setEditModalOpen(false);
    onUpdate();
  };

  const handleAnalyzeOracleAnalysis = async (fileId) => {
    try {
      await axios.post(`/api/v1/oracle/analyze-file?id=${fileId}`);
    } catch (error) {
      console.error('There was an error analyzing file in Skynet', error);
    }
  };

  return (
    <Paper variant="outlined" className="content">
      <Grid container spacing={1.75}>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant="body2"
              fontWeight={'bold'}
              color={'text.primary'}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {documentState.requirement_name}
            </Typography>
            <Box display={'flex'} alignItems={'center'}>
              <IconButton size="small" color="primary" onClick={handleUpload}>
                <Upload />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                onClick={() => setEditModalOpen(true)}
              >
                <EditOutlined />
              </IconButton>
              <TextField
                value={documentState.approval_status}
                size="small"
                variant="outlined"
                aria-label="Status"
                name="approval_status"
                onChange={handleStatusChange}
                required
                select
              >
                <MenuItem value={'VERIFIED'}>
                  <Chip
                    label={'Verified'}
                    sx={{
                      backgroundColor: 'success.light',
                      color: 'success.contrastText',
                    }}
                  />
                </MenuItem>
                <MenuItem value={'NEEDS_REVIEW'}>
                  <Chip
                    label={'Needs Review'}
                    sx={{
                      backgroundColor: 'info.veryLight',
                      color: 'info',
                    }}
                  />
                </MenuItem>
                <MenuItem value={'ISSUES_IDENTIFIED'}>
                  <Chip
                    label={'Issues Identified'}
                    sx={{
                      backgroundColor: 'error.light',
                      color: 'error.contrastText',
                    }}
                  />
                </MenuItem>

                <MenuItem value={'MISSING'}>
                  <Chip
                    label={'Missing'}
                    sx={{
                      backgroundColor: 'error.light',
                      color: 'error.contrastText',
                    }}
                  />
                </MenuItem>
              </TextField>
            </Box>
          </Box>
          <Typography
            variant="body2"
            fontSize={12}
            color={'text.secondary'}
            mt={0.5}
          >
            Created{' '}
            {documentState.created_at ??
              standardToShortDate(new Date().toISOString())}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color={'text.primary'}>
            {documentState.requirement_description || <br />}
          </Typography>
        </Grid>
        {documentState.files?.map((file) => {
          if (file.is_deleted !== true) {
            return (
              <>
                <Grid item xs={12} key={file.id}>
                  <Box
                    key={file.id}
                    sx={{
                      backgroundColor: '#F9FBFD',
                      borderRadius: '8px',
                      border: '1px solid #ECECEC',
                      px: 1.75,
                      py: 1.5,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                    >
                      <Box display={'flex'} alignItems={'center'}>
                        <InsertDriveFile
                          sx={{
                            fontSize: '17px',
                          }}
                        />
                        <Link
                          href={`/api/v1/loans/file/${file.id}`}
                          target={'_blank'}
                          download={file.file_name}
                        >
                          <Typography
                            variant="body2"
                            ml={1}
                            color={'text.primary'}
                          >
                            {file.file_name}
                          </Typography>
                        </Link>
                      </Box>
                      <Typography
                        variant="body2"
                        fontSize={12}
                        color={'text.secondary'}
                        display={'flex'}
                        flexWrap={'wrap'}
                      >
                        <Typography
                          variant="body2"
                          component={'span'}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            mr: 1,
                          }}
                        >
                          {formatDateTime(new Date(file.created_at))}
                        </Typography>
                        <Typography
                          variant="body2"
                          component={'span'}
                          sx={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {file.created_by}
                        </Typography>
                      </Typography>
                    </Box>
                    {isAdmin() && !file.has_oracle_insights && (
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            handleAnalyzeOracleAnalysis(file.id);
                          }}
                          color="inherit"
                          sx={{ mx: 0.5 }}
                        >
                          <img
                            src={skynetLogo}
                            style={{ width: '25px', marginRight: '5px' }}
                          />
                          Analyze
                        </Button>
                      </Box>
                    )}
                    {file.upload_source === 'RAILWAY' ? (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          setFileToDelete(file.id);
                          setDeleteModalOpen(true);
                        }}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    ) : (
                      <Box display="flex" alignItems={'center'} ml={1}></Box>
                    )}
                  </Box>
                </Grid>
                <ConfirmationModal
                  open={deleteModalOpen}
                  onClose={() => setDeleteModalOpen(false)}
                  onConfirm={() => {
                    handleDelete(fileToDelete);
                    setDeleteModalOpen(false);
                  }}
                  title="Confirm Deletion"
                  content="Are you sure you want to delete this file?"
                  cancelText="No, cancel"
                  confirmText="Yes, delete file"
                />
              </>
            );
          }
        })}
      </Grid>
      <RequiredDocumentModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleSubmit}
        initialDocument={documentState}
        editMode
      />
    </Paper>
  );
};
