import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
  accordionSummaryClasses,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddCheckModal } from './AddCheckModal';
import { AddCheckSetModal } from './AddCheckSetModal';
import { DocumentCheck } from './DocumentCheck';
import { StatusIcon } from './StatusIcon';
import {
  ChecklistItemRule,
  ChecklistItemVerificationStatus,
} from './constants';

export const ChecklistDocument = ({
  document,
  status,
  documentChecks,
  addCheck,
  addCheckSet,
  deleteCheck,
  updateCheck,
  parentChecksByTitle,
  checklistId,
  checklistTemplates,
  checklistItemTemplates,
  readOnly = false,
  forceClose,
  forceOpen,
}) => {
  const documentName = document.Display_Name__c;
  const approvalStatus = document.Approval_Status__c;

  const [addCheckModalOpen, setAddCheckModalOpen] = useState(false);
  const [addCheckSetModalOpen, setAddCheckSetModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(
    status !== ChecklistItemVerificationStatus.VERIFIED,
  );

  useEffect(() => {
    if (forceClose !== undefined) {
      setExpanded(false);
    }
  }, [forceClose]);

  useEffect(() => {
    if (forceOpen !== undefined) {
      setExpanded(true);
    }
  }, [forceOpen]);

  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded);
  };

  return (
    <div key={document.Id}>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore htmlColor="#333333" />}
          sx={{
            px: 0,
            flexDirection: 'row-reverse',
            [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
              transform: 'rotate(-90deg)',
              [`&.${accordionSummaryClasses.expanded}`]: {
                transform: 'rotate(0deg)',
              },
            },
            '& .MuiAccordionSummary-content': {
              marginTop: '0px !important',
              marginBottom: '0px !important',
            },
          }}
        >
          <Box display="flex" alignItems={'center'} sx={{ mt: 0 }}>
            <Typography
              variant="h6"
              mr={0.5}
              fontSize={18}
              fontWeight={'bold'}
              fontFamily={'Lato'}
            >
              {documentName}
            </Typography>
            <StatusIcon status={status} statusType="verification" />
            <StatusIcon
              status={approvalStatus}
              statusType="document_approval"
            />
            {!readOnly && (
              <Button
                variant="text"
                color="inherit"
                sx={{
                  fontSize: 12,
                  ml: 1,
                  mt: 0,
                  height: 20,
                  border: '1px solid',
                  borderColor: 'black',
                  backgroundColor: '#2e7d32',
                  '&:hover': {
                    backgroundColor: '#4e9d52',
                  },
                  textTransform: 'none',
                  color: 'white',
                }}
                onClick={() => {
                  setAddCheckSetModalOpen(true);
                }}
              >
                Add Verification Set
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
          }}
        >
          {documentChecks.length ? (
            <Grid container spacing={1.5} key={document.Id}>
              {documentChecks.map((check, i) => {
                const isChildCheck = check.rule === ChecklistItemRule.EQUAL;
                const parentCheck = isChildCheck
                  ? parentChecksByTitle[check.title]
                  : null;
                return (
                  <Grid
                    item
                    xs={12}
                    key={check.id}
                    sx={{ pt: i === 0 ? '0px !important' : undefined }}
                  >
                    <DocumentCheck
                      check={check}
                      updateCheck={updateCheck}
                      deleteCheck={deleteCheck}
                      parentCheckValue={parentCheck?.inputValue}
                      readOnly={readOnly}
                      showColumnTitles={i === 0}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : null}
          {!readOnly && (
            <Button
              variant="text"
              color="inherit"
              sx={{
                fontSize: 12,
                mt: 1,
                height: 20,
                border: '1px solid',
                borderColor: 'black',
                backgroundColor: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#4e9d52',
                },
                textTransform: 'none',
                color: 'white',
              }}
              onClick={() => {
                setAddCheckModalOpen(true);
              }}
            >
              Add Verification
            </Button>
          )}
        </AccordionDetails>
      </Accordion>

      <AddCheckSetModal
        open={addCheckSetModalOpen}
        onClose={() => {
          setAddCheckSetModalOpen(false);
        }}
        onSubmit={(items) => {
          return addCheckSet(items, documentName, documentChecks.length + 1);
        }}
        checklistTemplates={checklistTemplates}
      />

      <AddCheckModal
        open={addCheckModalOpen}
        onClose={() => {
          setAddCheckModalOpen(false);
        }}
        onSubmit={(settings) => {
          return addCheck({
            sunstoneDocumentName: documentName,
            inputValue: '',
            orderNumber: documentChecks.length + 1,
            checklistId: checklistId,
            ...settings,
          });
        }}
        checklistItemTemplates={checklistItemTemplates}
      />
    </div>
  );
};
